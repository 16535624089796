<template>
  <div></div>
</template>

<script>
import { storeToRefs } from "pinia";
import { useAuthStore } from "../stores/auth.js";
import { useFinanceiroStore } from "../stores/financeiro";
import { useContratosStore } from "../stores/contratos";
import { useLoginStore } from "../stores/login";
import { useTelefoniaStore } from "../stores/telefonia";
let isAuthenticated;
let FinanceiroStore;
let ContratosStore;
let AuthStore;
let LoginStore;
let TelefoniaStore;
let auth;
export default {
  methods: {
    async AtualizaDados() {
      await FinanceiroStore.AtualizaBoletosAbertos();
      await FinanceiroStore.AtualizaBoletosPagos();
      await ContratosStore.AtualizaContratos();
      await ContratosStore.AtualizaNotas();
      await LoginStore.AtualizaLogins();
      await LoginStore.AtualizaConsumoDiario(LoginStore.getLogins()[0].id);
      await TelefoniaStore.AtualizaTelefonia();
    },
  },

  data() {
    return {
      isAuthenticated,
      auth,
      AuthStore,
      FinanceiroStore,
      ContratosStore,
      LoginStore,
      TelefoniaStore,
    };
  },

  mounted() {
    if (this.auth) {
      this.AtualizaDados();
    }
  },

  setup() {
    AuthStore = useAuthStore();
    isAuthenticated = storeToRefs(AuthStore);
    auth = isAuthenticated.isAuthenticated;
    FinanceiroStore = useFinanceiroStore();
    ContratosStore = useContratosStore();
    LoginStore = useLoginStore();
    TelefoniaStore = useTelefoniaStore();
  },

  watch: {
    auth(logad) {
      if (logad) {
        this.AtualizaDados();
      }
    },
  },
};
</script>

/* watch(isAuthenticated, () => { })*/
