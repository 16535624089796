import { defineStore } from "pinia";
import { api } from "../boot/axios";

export const useContratosStore = defineStore("ContratosStore", {
  state: () => ({
    Contratos: [],
    TotalContratos: [],
    ContratosAtivos: [],
    Notas: [],
    TotalNotas: [],
  }),
  getters: {
    getContratos: (state) => () => {
      return state.Contratos;
    },
    getTotalContratos: (state) => () => {
      return state.TotalContratos;
    },
    getNotas: (state) => () => {
      return state.Notas;
    },
    getTotalNotas: (state) => () => {
      return state.TotalNotas;
    },
  },
  actions: {
    async AtualizaContratos() {
      await api
        .post("contrato")
        .then((response) => {
          this.Contratos = response.data.contratos;
          this.TotalContratos = response.data.contratos.total;
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            return error.response.data;
          }
        });
    },
    async AtualizaNotas(IdContrato) {
      await api
        .post("contrato/notas")
        .then((response) => {
          this.TotalNotas = response.data.notas.total
          let resultado = []
          let notasAgrupadas = []
          if (response.data.notas.total != 0) {
            response.data.notas.registros.sort((a, b) => new Date(b.data_emissao) - new Date(a.data_emissao));
            if (IdContrato != undefined) {
              resultado = response.data.notas.registros.filter((objeto) => Number(objeto.id_contrato) == Number(IdContrato));
            }

            resultado.forEach((nota) => {
              const { id, data_emissao, valor_total } = nota;
              const dataEmissao = new Date(data_emissao);
              const mes = String(dataEmissao.getMonth() + 1).padStart(2, "0");
              const ano = dataEmissao.getFullYear();
          
              const mesAno = `${mes}/${ano}`;
          
              if (!notasAgrupadas[mesAno]) {
                notasAgrupadas[mesAno] = {
                  id: id,
                  mes: mes,
                  ano: ano,
                  valor_total_mes: 0,
                  notas: [],
                };
              }
        
              notasAgrupadas[mesAno].notas.push(nota);
              notasAgrupadas[mesAno].valor_total_mes += parseFloat(valor_total);
            });

            this.Notas = Object.values(notasAgrupadas)
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            return error.response.data;
          }
        });
    }
  }
});