import autenticacaoroutes from "./autenticacao.routes";
import perfilroutes from "./perfil.routes";
import usuariosroutes from "./usuarios.routes";
import faturasroutes from "./faturas.routes";
import monitoramentoroutes from "./monitoramento.routes";
import bonusroutes from "./bonus.routes";
import telefoniaroutes from "./telefonia.routes";
import Dashboard from "@/views/Dashboard.vue";
import Contratos from "@/views/Contratos.vue";
import Home from "@/views/Home.vue";

var routes = [
  {
    path: "/404",
    name: "not-found",
    component: Home,
    meta: {
      layout: 'bonus'
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found1",
    component: Home,
    meta: {
      layout: 'bonus'
    },
  },
  {
    path: "/:catchAll(.*)*",
    name: "not-found2",
    component: () => import("../views/Home.vue"),
    meta: {
      layout: 'bonus'
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      layout: 'sistema'
    },
  },
  {
    path: "/contratos",
    name: "Contratos",
    component: Contratos,
    meta: {
      requiresAuth: true,
      layout: 'sistema',
      Nivel: 1
    },

  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: 'bonus',
    },
  },
  {
    path: '/politica',
    beforeEnter() { location.href = "/arquivos/politica.pdf" },
  },
];

routes = routes.concat(autenticacaoroutes)
routes = routes.concat(perfilroutes)
routes = routes.concat(usuariosroutes)
routes = routes.concat(faturasroutes)
routes = routes.concat(monitoramentoroutes)
routes = routes.concat(bonusroutes)
routes = routes.concat(telefoniaroutes)
export default routes;